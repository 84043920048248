// @flow
import {useEffect, useState} from 'react';

export const useImage = (src: string): {loaded: boolean, error: boolean} => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const image = new Image();
    image.onload = () => setLoaded(true);
    image.onerror = () => setError(true);
    image.src = src;
  }, []);

  return {loaded, error};
};
