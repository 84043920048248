// React
import React, {useEffect, useState} from 'react';
import type {ComponentType} from 'react';

// Third party
import {Outlet} from 'react-router-dom';
import moment from 'moment';
import {observer} from 'mobx-react';
import 'emoji-mart/css/emoji-mart.css';

// Bulma
import {Heading, Level, Tag} from 'react-bulma-components';
import {Paper} from '@wellstone-solutions/web';

// WS
import {Api, BridgeEventSources, Events} from '@wellstone-solutions/common';

import {Stack, Typography} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {genders, identities} from 'utils/Forms';
import {NavTabs} from 'modules/navigation';
import TutorialDiv from 'components/tutorial/tutorialdiv';
import {formatEvents, processData} from 'utils/Charts';
import EditMemberForm from 'components/forms/member/EditMemberForm';
import PfChatModule from 'components/chat/PfChatModule';
import PfLoader from 'components/shared/PfLoader';
import PfModal from 'components/modal/PfModal';
import {SinceCheckin} from 'components/member/SinceCheckin';
import {EventEmitter} from 'utils/EventEmitter';
import {Palette} from 'palette';
import {ResendInvitationEmail} from '../ResendInvitationEmail';
import {hasFlag, TMP_PEERS_DOCUMENTATION} from 'constants/FeatureFlags';
import CommunitySwitch from 'components/forms/components/CommunitySwitch';
import {ENTITY_LEVEL, ENTITY_TYPES, AccessControl} from 'modules/rbac';
import {MemberDetailTabContext} from '../../contexts/MemberDetailTabContext';
import {EHRConnect} from 'modules/integration';
import {ErrorPage} from 'components/ErrorPage';

type PropsType = {};

export const MemberDetail: ComponentType<PropsType> = observer(
  ({router, memberNotifications}) => {
    const [state, setState] = useState(false);
    const {member: memberId} = router.params;

    const {
      eventStore,
      appUIStore,
      meStore,
      memberDetailStore,
      RBACStore,
    } = useStores();

    const hasGroupAccess = (groups) => {
      // this user has specific authorization for this group
      const isMine = groups
        ? groups.some((group) =>
            RBACStore.hasAccessToEntity(ENTITY_TYPES.group, group),
          )
        : false;
      // if the current user is an admin or a primary counselor
      return RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.program) || isMine;
    };

    const {currentMember} = memberDetailStore;
    const hasPrimaryAccess = hasGroupAccess(memberDetailStore.primaryGroups);
    const canViewSessionDocTab = currentMember
      ? hasGroupAccess(
          currentMember.group_admissions.map((ga) => ga.group.id),
        ) && hasFlag(TMP_PEERS_DOCUMENTATION, meStore.features)
      : false;

    const canAssignEHRIntegration =
      !!meStore.ehrIntegrationOption &&
      RBACStore.hasAccess(AccessControl.staff.canAssignEHRIntegrations);

    const setFocus = () => {
      setState({...state, isFocused: !state.isFocused});
    };

    useEffect(() => {
      eventStore.setEventSource(BridgeEventSources.MEMBER_DETAILS_PAGE);
      EventEmitter.subscribe('notification', handleNotification);
      loadUX();

      return () => {
        EventEmitter.unsubscribe('notification', handleNotification);
      };
    }, [router.params.member]);

    useEffect(() => {
      getData();
    }, [state.aggregator]);

    useEffect(() => {
      if (
        state.selectionRange &&
        state.selectionRange.startDate !== state.selectionRange.endDate
      ) {
        EventEmitter.dispatch('closeModal');
        getData();
      }
    }, [state.selectionRange]);

    const loadUX = async () => {
      const {member} = router.params;

      eventStore.addEvent(Events.VIEWED_MEMBER_DETAIL, {
        member_id: member, // this is the member id
      });

      let start = new Date();
      let end = new Date();
      start.setDate(start.getDate() - 6);
      end.setDate(end.getDate() + 1);

      const stateUpdates = {
        aggregator: 'date',
        selectionRange: {
          startDate: start,
          endDate: end,
          key: 'selection',
        },
        mainColumn: 6,
      };

      const org = meStore.me.membership.organization.id;

      try {
        const response = await memberDetailStore.getCurrentMember(org, member);

        if (!response.isSuccess) {
          setState({...state, ...stateUpdates, ready: true});
          return;
        }

        await memberDetailStore.syncCurrentMemberHabits();
        appUIStore.setBreadcrumbs(memberDetailStore.getBreadCrumbs());
        const {recentEvents, latestCheckin} = await getRecentEvents();

        setState({
          ...state,
          ...stateUpdates,
          recentEvents,
          latestCheckin,
          ready: true,
        });

        // await getData();
      } catch (e) {
        setState({...state, ...stateUpdates, ready: true});
      }
    };

    const getMember = async () => {
      let {member} = router.params;

      const org = meStore.me.membership.organization.id;

      try {
        const response = await Api.Instance.current().get(
          '/orgs/' + org + '/members/' + member,
        );
        memberDetailStore.setCurrentMember(response.data);
        setState({...state, ready: true});
      } catch (e) {
        // TODO: (UX, Jer) if getting member fails?
        console.log('Failed to get member', e);
      } finally {
        setState({...state, ready: true});
      }
    };

    const handleNotification = async (event) => {
      const userId = memberDetailStore.currentMember.user.id;
      let isTarget = false;
      switch (event.event_type) {
        case 'user_marked_message':
          if (event.data.message.data.author === userId) {
            isTarget = true;
          }
          break;
        default:
          if (event.data.user_id === userId) {
            isTarget = true;
          }
          break;
      }

      if (isTarget) {
        const {recentEvents, latestCheckin} = await getRecentEvents();

        setState({
          ...state,
          recentEvents,
          latestCheckin,
        });
      }
    };

    const getRecentEvents = async () => {
      const {member} = router.params;

      const params = {
        limit: 30,
        member_id: member,
        category: [
          'user_marked_message',
          'user_added_friend',
          'user_relapsed',
          'user_added_checkin',
        ],
        only_patient: 1,
      };
      const org = meStore.me.membership.organization.id;

      try {
        const response = await Api.Instance.current().get(
          '/orgs/' + org + '/events',
          {params},
        );
        const recentEvents = formatEvents(response.data.events).sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        });
        const latestCheckin =
          recentEvents[
            recentEvents.findIndex((x) => x.category === 'user_added_checkin')
          ];

        return {
          recentEvents: recentEvents,
          latestCheckin: latestCheckin,
        };
      } catch (e) {}
    };

    const getChatModule = () => {
      if (state.ready && state.mainColumn !== 12) {
        return (
          <TutorialDiv id="chat-wrapper">
            <PfChatModule
              user={memberDetailStore.currentMember.user.id}
              name={memberDetailStore.currentMember.name}
            />
          </TutorialDiv>
        );
      } else {
        return null;
      }
    };

    const handleSelectAggregator = async (sel) => {
      setState({...state, aggregator: sel});
    };

    const getData = async () => {
      if (
        !state.selectionRange?.startDate ||
        !state.selectionRange?.endDate ||
        !memberDetailStore.currentMember
      ) {
        return;
      }

      let {member} = router.params;
      let start = moment(state.selectionRange.startDate).format('YYYY-MM-DD');
      let end = moment(state.selectionRange.endDate).format('YYYY-MM-DD');

      const params = {
        limit: 999999,
        range_start: start,
        range_end: end,
        member_id: member,
        only_patient: 1,
      };
      const org = meStore.me.membership.organization.id;

      try {
        let response = await Api.Instance.current().get(
          '/orgs/' + org + '/events',
          {params},
        );
        const otherData = await processData(org, response, state);
        const habitData = memberDetailStore.getActivityData({
          aggregator: state.aggregator,
          eventsData: response.data.events,
          eventCategory: 'user_completed_habit',
          groupingFn: memberDetailStore.groupHabits,
          selectionRange: state.selectionRange,
        });

        const allData = {...otherData, habitData};

        setState({...state, ...allData});

        return allData;
      } catch (error) {
        console.log('Charts:error', error);
      }
    };

    if (!state.ready) {
      return <PfLoader />;
    }

    if (!currentMember) {
      return <ErrorPage message="Member not found" isFullScreen={false} />;
    }

    const uiGender =
      genders.find(({value}) => value === currentMember.gender)?.name || 'N/A';
    const uiIdentifiesAs =
      identities.find(({value}) => value === currentMember.identifies_as)
        ?.name || 'N/A';

    const tabs = [
      {
        label: 'General',
        value: `general`,
        to: `/members/${memberId}`,
      },
    ];

    if (RBACStore.hasAccess(AccessControl.members.canAddProgramEnrollment)) {
      tabs.push({
        label: 'Programs & Groups',
        value: `permissions`,
        to: `/members/${memberId}/permissions`,
      });
    }

    tabs.push({
      label: 'Activity',
      value: `activity`,
      to: `/members/${memberId}/activity`,
    });

    tabs.push({
      label: 'Habit Schedule',
      value: `habit-schedule`,
      to: `/members/${memberId}/habit-schedule`,
    });

    if (canViewSessionDocTab) {
      tabs.push({
        label: 'Documentation',
        value: `session-docs`,
        to: `/members/${memberId}/session-docs`,
      });
    }

    tabs.push({
      value: 'focus',
      label: state.isFocused ? 'Expand' : 'Focus',
      onClick: () => setFocus(true),
      style: {marginLeft: 'auto'},
    });

    return (
      <div>
        {!state.isFocused ? (
          <Paper sx={{padding: 3, marginBottom: 4}}>
            <Level style={styles.level}>
              <Level.Side style={styles.stackedLeftItemContainer}>
                <Level.Item style={styles.stackedLeftItems}>
                  <div style={styles.stackedLeftInner}>
                    <Heading size={4} style={styles.memberName}>
                      {currentMember.name}
                    </Heading>
                    {canAssignEHRIntegration && (
                      <EHRConnect
                        memberType={'member'}
                        member={currentMember}
                      />
                    )}
                    {hasPrimaryAccess && currentMember.admissions.length > 0 ? (
                      <CommunitySwitch member={currentMember} withLabel />
                    ) : (
                      <div
                        style={{
                          ...styles.italic,
                          ...{
                            color: currentMember.has_community_access
                              ? Palette.SUCCESS
                              : Palette.INFO,
                          },
                        }}>
                        {currentMember.has_community_access
                          ? 'Has peer-to-peer access'
                          : 'Does not have peer-to-peer access'}
                      </div>
                    )}
                    <TutorialDiv
                      id="since-checkin-counter"
                      styles={styles.inlineBlock}>
                      {<SinceCheckin checkIn={currentMember.last_check_in} />}
                    </TutorialDiv>

                    {RBACStore.hasAccess(AccessControl.members.sendReinvite) &&
                      !currentMember.last_opened_app && (
                        <ResendInvitationEmail
                          id={currentMember.id}
                          lastInviteSent={currentMember.invite_sent?.created}
                          memberCreated={currentMember.created}
                          name={currentMember.name}
                          email={currentMember.user.email}
                          organizationId={meStore.organizationId}
                        />
                      )}
                  </div>
                  {currentMember.birthdate && (
                    <Typography>DOB: {currentMember.birthdate}</Typography>
                  )}
                  {currentMember.user.email && (
                    <Typography style={styles.stackedLevelItemBig}>
                      {currentMember.user.email}
                    </Typography>
                  )}
                  <Tag.Group gapless style={styles.stackedLevelItem}>
                    <Tag color="dark">Sex</Tag>
                    <Tag color="info">{uiGender}</Tag>
                  </Tag.Group>
                  <Tag.Group gapless style={styles.stackedLevelItem}>
                    <Tag color="dark">Identifies as</Tag>
                    <Tag color="info">{uiIdentifiesAs}</Tag>
                  </Tag.Group>
                </Level.Item>
              </Level.Side>
              <Level.Side style={styles.stackedRightItems}>
                <Level.Item style={styles.stackedRightInner}>
                  {RBACStore.hasAccess(AccessControl.members.editMember) && (
                    <PfModal
                      button={{outlined: true, text: 'Edit Member'}}
                      large
                      modal={{
                        closeOnBlur: true,
                        showClose: false,
                        closeOnEsc: true,
                      }}
                      onCloseModal={getMember}>
                      <Heading size={4}>Edit Member</Heading>
                      <EditMemberForm member={currentMember} />
                    </PfModal>
                  )}
                </Level.Item>
              </Level.Side>
            </Level>
          </Paper>
        ) : null}
        <Stack
          mb={4}
          sx={{borderBottom: 1, borderColor: 'divider', width: '100%'}}>
          <NavTabs tabs={tabs} />
        </Stack>
        <MemberDetailTabContext.Provider
          value={{
            getChatModule,
            member: memberDetailStore.currentMember,
            searchBoxTally: meStore.searchBoxTally,
            getData,
            state,
            setState,
            memberNotifications,
            onSelectAggregator: handleSelectAggregator,
          }}>
          <Outlet />
        </MemberDetailTabContext.Provider>
      </div>
    );
  },
);

const styles = {
  stackedLeftItemContainer: {
    alignItems: 'flex-start',
  },
  stackedLeftItems: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  stackedRightItems: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '65%',
  },
  stackedLevelItem: {
    marginBottom: 5,
  },
  stackedLevelItemBig: {
    marginBottom: 8,
  },
  basicInfo: {
    display: 'block',
    marginTop: 10,
    color: Palette.GREY_2,
  },
  memberName: {
    marginBottom: 10,
    color: Palette.GREY_4,
  },
  infoBlock: {
    marginBottom: 20,
  },
  inlineBlock: {display: 'inline-block'},
  groupHeader: {
    fontSize: 16,
    fontWeight: 700,
    color: Palette.subtle4,
    marginTop: 10,
    marginBottom: 10,
  },
  adminHeader: {
    textAlign: 'right',
    backgroundColor: Palette.PRIMARY,
    padding: 10,
    borderRadius: 5,
  },
  adminHeaderText: {
    color: 'white',
  },
  chatHeaders: {
    marginLeft: 5,
    marginRight: 5,
  },
  patientHeader: {
    backgroundColor: Palette.LIGHT_INFO,
    padding: 10,
    borderRadius: 5,
  },
  patientHeaderText: {
    color: Palette.PRIMARY,
  },
  viewBy: {
    fontWeight: 'bold',
    color: Palette.INFO,
  },
  gradient: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage:
      'linear-gradient(0deg, white, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 9,
    top: '-0.5rem',
    left: '0rem',
    pointerEvents: 'none',
  },
  boxrelative: {
    position: 'relative',
    backgrouncColor: '#FFF',
  },
  level: {
    alignItems: 'stretch',
  },
  stackedLeftInner: {
    marginBottom: 10,
  },
  stackedRightInner: {
    marginBottom: 10,
    marginTop: 20,
    flexGrow: 0,
  },
  tabSpacer: {
    flex: 2,
  },
  streamContainer: {
    position: 'relative',
  },
  relative: {
    position: 'relative',
    height: 40,
  },
  button: {
    height: 40,
    right: 0,
  },
  program: {
    fontWeight: '600',
    padding: 10,
    fontSize: 16,
    margin: 0,
  },
  block: {
    display: 'block',
  },
  m0: {
    margin: 0,
  },
  italic: {
    fontStyle: 'italic',
  },
  notActive: {
    color: Palette.GREY_4,
    fontStyle: 'italic',
    fontSize: 15,
  },
};
