import {observable, action, computed, makeObservable} from 'mobx';
import {Api} from '@wellstone-solutions/common';
import type {ApiResponseType} from '@wellstone-solutions/common';
import {buildIdLookup} from 'utils/buildIdLookup';
import {ENTITY_LEVEL} from 'modules/rbac';

export class GroupStore {
  groups = [];
  myGroups = [];

  addGroup = async (
    org: string,
    params: any,
  ): Promise<ApiResponseType<{id: string}>> => {
    const response = await Api.Instance.current().post(
      '/orgs/' + org + '/groups',
      params,
    );
    return response;
  };

  get myGroupsLookup() {
    return buildIdLookup(this.myGroups);
  }

  get RBACStore() {
    return this.rootStore.stores.RBACStore;
  }

  constructor(rootStore) {
    makeObservable(this, {
      groups: observable,
      myGroups: observable,
      myGroupsLookup: computed,
      loadGroups: action,
    });

    this.rootStore = rootStore;
  }

  init = async () => {
    const {meStore} = this.rootStore.stores;

    await this.requestMyGroups(meStore.permittedGroupIds);
  };

  loadGroups = async (org, isInTutorial) => {
    let tmpOrg = org
      ? org
      : this.rootStore.stores.meStore.me.membership.organization.id;
    const params = {
      limit: 999,
      offset: 0,
    };
    try {
      const response = await Api.Instance.current().get(
        '/orgs/' + tmpOrg + '/groups',
        {params},
      );
      this.groups = response.data.groups;
      if (!isInTutorial) {
        this.rootStore.stores.meStore.setTutorial();
      }
    } catch (e) {}
  };

  requestMyGroups = async (ids) => {
    const allGroupsAccess = this.RBACStore.hasEntityLevelAccess(
      ENTITY_LEVEL.all,
    );
    // We get all groups back when an empty id list is provided
    // So, unless we are a super admin, we can never query all groups
    if (ids.length === 0 && !allGroupsAccess) {
      return;
    }

    let org = this.rootStore.stores.meStore.me.membership.organization.id;
    const params = {
      limit: 999,
      offset: 0,
      // Empty array returns all
      id: allGroupsAccess ? [] : ids,
    };

    const response = await Api.Instance.current().get(
      '/orgs/' + org + '/groups',
      {params},
    );

    this.myGroups = response.data.groups;
  };

  groupsByType = (type) => {
    return this.groups.filter((group, g) => {
      // return group.category?.name?.toLowerCase() === type.toLowerCase();
      return group.category?.type?.name?.toLowerCase() === type.toLowerCase();
    });
  };

  getGroupById = (id) => {
    let filtered = this.groups.filter((group, g) => {
      return group.id === id;
    });
    return filtered[0]
      ? filtered[0]
      : {name: 'Unknown', category: {name: 'Unknown'}};
  };
}
