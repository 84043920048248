// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {Events, BridgeEventSources} from '@wellstone-solutions/common';
import {Box, Stack} from '@wellstone-solutions/web';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import {PfButton} from 'components/shared/PfButton';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {Calendar, Constants} from 'modules/calendar';

type PropsType = {...};

export const CalendarPage = (props: PropsType): Node => {
  const {appUIStore, eventStore, meStore} = useStores();
  const navigate = useNavigate();

  useEventSource(BridgeEventSources.CALENDAR_PAGE);

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.calendar,
        url: '/calendar',
        active: true,
      },
    ]);

    eventStore.addEvent(Events.VIEWED_CALENDAR, {
      view: meStore.activeCalendarView || Constants.CALENDAR_VIEWS.month,
    });
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
      }}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 4,
        }}>
        <PfButton
          color="primary"
          outlined
          onClick={() => navigate('/calendar/calendar-event/new')}>
          Add New Event
        </PfButton>
      </Stack>
      <Calendar />
    </Box>
  );
};
