// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Box, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {imageGCBucketUrl, iconNameToImageUrl} from 'utils/images';
import {useImage} from 'hooks/useImage';

type RemoteIconProps = {
  name: string,
  size: number,
  path: string,
  isSelected?: boolean,
  label?: string,
  disableBorder?: boolean,
};

export const RemoteIcon = ({
  name,
  size,
  path,
  isSelected = false,
  label = '',
  disableBorder = false,
}: RemoteIconProps): Node => {
  const baseUrl = imageGCBucketUrl(path);
  const defaultIcon = `${baseUrl}/default.svg`;
  const iconUrl = iconNameToImageUrl(name, {
    baseUrl,
    extension: 'svg',
  });

  const [src, setSrc] = useState(null);
  const {loaded, error} = useImage(iconUrl);

  useEffect(() => {
    if (loaded) {
      setSrc(iconUrl);
    } else if (error) {
      setSrc(defaultIcon);
    }
  }, [loaded, error]);

  return (
    <Box data-testid="remote-icon" sx={styles.container}>
      {!disableBorder && (
        <svg height={size} width={size} style={styles.backgroundCircle}>
          <circle
            data-testid="background-circle"
            cx="50%"
            cy="50%"
            r={`calc(50% - 0.5px)`}
            fill={isSelected ? Theme.colorPalette.secondary4 : 'transparent'}
            stroke={
              isSelected
                ? Theme.colorPalette.secondary3
                : Theme.colorPalette.medium
            }
            strokeWidth={1}
          />
        </svg>
      )}
      {!!src && (
        <img
          alt={name}
          src={src}
          width={size}
          height={size}
          style={styles.image}
        />
      )}
      {label && (
        <Typography data-testid="icon-label" sx={styles.label}>
          {label}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  backgroundCircle: {
    position: 'absolute',
    zIndex: 1,
  },
  image: {
    zIndex: 2,
  },
  label: {
    fontSize: '11px',
    textAlign: 'center',
    color: Theme.colorPalette.onSurface,
    marginTop: 1,
  },
};
