import React, {useState, useCallback, useEffect} from 'react';
import {Level, Heading} from 'react-bulma-components';
import {Alert, Select} from '@wellstone-solutions/web';

import {Api} from '@wellstone-solutions/common';
import PfModal from '../../modal/PfModal';
import {getRawDateRange, processRawData} from '../../../utils/Charts';
import {DateRangePicker} from 'react-date-range';
import {Palette} from '../../../palette';
import {aggregatingList} from '../../../constants/Aggregators';
import MembersOverview from '../../MembersOverview';
import moment from 'moment';
import PfLoader from '../../shared/PfLoader';
import {EventEmitter} from '../../../utils/EventEmitter';

const Activity = (props) => {
  const [aggregator, setAggregator] = useState('date');
  const [isLoading, setIsLoading] = useState(true);

  let start = new Date();
  let end = new Date();
  start.setDate(start.getDate() - 6);
  end.setDate(end.getDate() + 1);
  const [selectionRange, setSelectionRange] = useState({
    startDate: start,
    endDate: end,
    key: 'selection',
  });

  const [processedData, setProcessedData] = useState();

  const getEventData = useCallback(async () => {
    setIsLoading(true);
    const {startDate, endDate} = selectionRange;

    const get = async (organization, params) => {
      try {
        return await Api.Instance.current().get(
          `/orgs/${organization}/events`,
          {params},
        );
      } catch (err) {
        console.warn(err);
      }
    };

    const response = await get(props.org, {
      limit: 99999,
      range_start: moment(startDate).format('YYYY-MM-DD'),
      range_end: moment(endDate).format('YYYY-MM-DD'),
      group_id: props.groupId,
    });

    const processed = await processRawData(
      props.org,
      response,
      selectionRange,
      aggregator,
    );

    setProcessedData(processed);
    setIsLoading(false);
    // We do not want to track the change of selectionRange.startDate
    // Only when the endDate has changed do we want to call the api
    // I have not found a way to do this without warnings so I am disabling
  }, [aggregator, props.groupId, props.org, selectionRange.endDate]);

  useEffect(() => {
    getEventData();
  }, [getEventData]);

  return (
    <div>
      <Level>
        <Level.Side>
          <Level.Item>
            <Heading style={styles.heading} size={4}>
              Group Activity
            </Heading>
          </Level.Item>
        </Level.Side>

        <Level.Side>
          <Level.Item>
            <PfModal
              button={{
                outlined: true,
                text: getRawDateRange(
                  selectionRange.startDate,
                  selectionRange.endDate,
                ),
              }}
              modal={{
                closeOnBlur: true,
                showClose: false,
                closeOnEsc: true,
              }}>
              <DateRangePicker
                ranges={[selectionRange]}
                rangeColors={[Palette.LINK]}
                id={'groupDateRangePicker'}
                onChange={(ranges) => {
                  const {startDate, endDate} = ranges.selection;
                  const selection = {};
                  if (startDate !== selectionRange.startDate) {
                    selection.startDate = startDate;
                  } else if (startDate !== endDate) {
                    selection.endDate = endDate;
                    EventEmitter.dispatch('closeModal');
                  }
                  setSelectionRange({...selectionRange, ...selection});
                }}
              />
            </PfModal>
          </Level.Item>
          <Level.Item>{<span style={styles.viewBy}>View by</span>}</Level.Item>
          <Level.Item style={styles.relative}>
            <Select
              value={aggregator}
              onChange={(event) => {
                setAggregator(event.target.value);
              }}
              items={aggregatingList.map((agg) => ({
                value: agg.key,
                label: agg.name,
              }))}
              canUnselect={false}
            />
          </Level.Item>
        </Level.Side>
      </Level>

      {isLoading ? (
        <PfLoader />
      ) : !processedData?.charts?.engagement ? (
        <Alert style={styles.notificationNoData}>
          NO DATA FOUND FOR THIS PERIOD
        </Alert>
      ) : (
        <MembersOverview
          aggregator={aggregator}
          checkinData={processedData.checkinData}
          activityData={processedData.charts.activity}
          habitData={[]}
          badgeData={processedData.charts.badge}
          appnavigationData={processedData.charts.appnavigationData}
          engagementData={processedData.charts.engagement}
          memberNotifications={props.memberNotifications}
          start={selectionRange.startDate}
          end={selectionRange.endDate}
          rawEvents={processedData.rawEventData}
        />
      )}
    </div>
  );
};

const styles = {
  heading: {
    color: Palette.GREY_4,
  },
  viewBy: {
    fontWeight: 'bold',
    color: Palette.GREY_4,
  },
  relative: {
    position: 'relative',
    height: 40,
  },
  notificationNoData: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
};

export default Activity;
