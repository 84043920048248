// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {BridgeEventSources, Events} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {MemberListV2} from './MemberListV2';

export const MemberRoot = (): Node => {
  const {appUIStore, eventStore} = useStores();

  useEventSource(BridgeEventSources.MEMBERS_PAGE);

  useEffect(() => {
    appUIStore.setBreadcrumbs([]);
    eventStore.addEvent(Events.VIEWED_MEMBER_LIST);
  }, [appUIStore]);

  return <MemberListV2 />;
};
