// @flow
import React from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Theme} from '@wellstone-solutions/common';
import type {UIMemberBasicType} from '@wellstone-solutions/common';
import {Card, CardContent, Stack, Typography} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {DATE_FORMAT_SLASH} from 'modules/documentation/constants';
import {CompletedRow} from 'modules/integration/components/CompletedRow';
import {EHRStatusView} from 'modules/integration/components';

type PropTypes = {
  member: UIMemberBasicType,
};

export const MemberCard = ({member}: PropTypes): Node => {
  const {meStore} = useStores();
  const orgIntegration = meStore.ehrIntegrationOption;

  return (
    <Card sx={{my: Theme.spacing.mini}}>
      <CardContent>
        <Stack
          direction="column"
          justifyContent={{xs: 'space-between', md: 'flex-start'}}
          alignItems="flex-start">
          <Stack direction="column">
            <Typography variant="h5">{member.name}</Typography>

            {orgIntegration && (
              <EHRStatusView
                orgIntegration={orgIntegration}
                integrations={member.integrations}
              />
            )}
          </Stack>
          <CompletedRow
            label={'Birthdate'}
            value={
              member.birthdate
                ? moment(member.birthdate).format(DATE_FORMAT_SLASH)
                : 'Unknown'
            }
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
