// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import type {FormType, UICalendarEventType} from '@wellstone-solutions/common';
import {Box, Checkbox, Stack, Typography} from '@wellstone-solutions/web';
import {DaysOfMonth} from './DaysOfMonth';
import {DaysOfWeek} from './DaysOfWeek';
import {IntervalSelect} from './IntervalSelect';
import {FreqSelect} from './FreqSelect';
import {RECURRENCE_FREQ_MAP} from 'modules/calendar/constants';

type PropsType = {
  form: FormType<UICalendarEventType>,
};

const DEFAULT_YEAR_DAY = 11;

export const Recurrence = ({form}: PropsType): Node => {
  const frequency = form.values.recurrence?.freq;
  const interval = form.values.recurrence?.interval;
  const isChecked = form.values.isRecurring;
  const label = isChecked ? 'Repeat every' : 'Repeat';

  // $FlowFixMe
  const isDisabled = form.initialValues?.isRecurring;

  useEffect(() => {
    const {startDate, recurrence} = form.values;
    const {bymonthday, byweekday} = recurrence;

    if (
      frequency === RECURRENCE_FREQ_MAP.monthly &&
      bymonthday === 0 &&
      byweekday === ''
    ) {
      form.setFieldValue('recurrence.bymonthday', moment(startDate).date());
    }
  }, [frequency]);

  return (
    <Stack>
      <Stack direction="row">
        <Checkbox
          disabled={isDisabled}
          checked={isChecked}
          label={label}
          value={isChecked}
          onChange={(event) => {
            form.setFieldValue('isRecurring', event.target.checked);
          }}
        />
        {isChecked && (
          <>
            <IntervalSelect
              onChange={(updatedInterval) => {
                form.setFieldValue('recurrence.interval', updatedInterval);
              }}
              value={interval}
            />
            <Box sx={{ml: 1}}>
              <FreqSelect
                disabled={isDisabled}
                onChange={(freq) => {
                  form.setFieldValue('recurrence.freq', freq);

                  // When yearly, we just set the year date to a default value
                  if (freq === RECURRENCE_FREQ_MAP.yearly) {
                    form.setFieldValue(
                      'recurrence.byyearday',
                      DEFAULT_YEAR_DAY,
                    );
                  }
                }}
                value={frequency}
              />
            </Box>
          </>
        )}
      </Stack>
      {isChecked && frequency === RECURRENCE_FREQ_MAP.weekly && (
        <Stack alignItems="center" direction="row" sx={{mt: 2}}>
          <Typography sx={{mr: 2}}>Repeat on</Typography>
          <DaysOfWeek
            onChange={(byweekday) => {
              form.setFieldValue('recurrence.byweekday', byweekday);
            }}
            value={form.values.recurrence?.byweekday}
          />
        </Stack>
      )}
      {isChecked &&
        form.values.recurrence?.freq === RECURRENCE_FREQ_MAP.monthly && (
          <Stack alignItems="center" direction="row" sx={{mt: 2}}>
            <Typography sx={{mr: 2}}>Repeat on</Typography>
            <DaysOfMonth
              recurrence={form.values.recurrence}
              startDate={form.values.startDate}
              onChange={(values) => {
                form.setFieldValue('recurrence', values);
              }}
            />
          </Stack>
        )}
      {isChecked && frequency === RECURRENCE_FREQ_MAP.yearly && (
        <Stack alignItems="center" direction="row" sx={{mt: 2}}>
          <Typography>{`On ${moment(form.values.startDate).format(
            'MMMM Do',
          )}`}</Typography>
        </Stack>
      )}
    </Stack>
  );
};
