// @flow
import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  Box,
  MultiSelect,
  Progress,
  ProgressTypes,
  Stack,
} from '@wellstone-solutions/web';
import {Roles} from '@wellstone-solutions/common';
import type {FormType} from '@wellstone-solutions/common';
import type {ListFilterFormType} from '../../../model/types';
import {useStores} from 'hooks/useStores';
import {sortBy} from 'utils/Utils';
import {PfButton} from 'components/shared/PfButton';
import {
  DOCUMENT_STATUSES,
  DOCUMENT_STATUS_LOOKUP,
  SESSION_TYPES,
} from '../../../constants';
import {DirectorySearch} from 'modules/organization';

type PropsType = {
  form: FormType<ListFilterFormType>,
  showMemberFilter?: boolean,
  showStaffFilter?: boolean,
  showProgramFilter?: boolean,
};

export const FilterForm = ({
  form,
  showMemberFilter = false,
  showStaffFilter = false,
  showProgramFilter = false,
}: PropsType): Node => {
  const [isLoading, setIsLoading] = useState(true);
  const [serviceTypeItems, setServiceTypeItems] = useState([]);
  const {sessionDocumentStore, meStore} = useStores();

  let programItems = [];
  if (showProgramFilter) {
    programItems = sortBy(meStore.myPrograms, 'name').map(({id, name}) => ({
      value: id,
      label: name,
    }));
  }

  const statusItems = Object.keys(DOCUMENT_STATUS_LOOKUP)
    .filter((key) => key !== DOCUMENT_STATUSES.created)
    .map((key) => ({
      value: key,
      label: DOCUMENT_STATUS_LOOKUP[key].label,
    }));

  useEffect(() => {
    const getServiceTypes = async () => {
      const response = await sessionDocumentStore.getServiceTypes(
        meStore.organizationId,
      );

      if (response.isSuccess) {
        const serviceTypes = response.data.map(({id, name}) => ({
          value: id,
          label: name,
        }));
        setServiceTypeItems(serviceTypes);
      }

      setIsLoading(false);
    };

    getServiceTypes();
  }, []);

  if (isLoading) {
    return (
      <Box sx={[styles.spinnerWrapper, styles.container]}>
        <Progress
          size="small"
          color="secondary"
          progressType={ProgressTypes.circular}
          data-testid="loading-spinner"
          style={styles.spinner}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <Stack spacing={2} mb={3}>
        {showMemberFilter && (
          <DirectorySearch
            label="Member name"
            roles={[Roles.PATIENT]}
            value={form.values.member}
            onOptionSelect={(newValue) => {
              form.setFieldValue('member', newValue);
              form.setFieldTouched('member');
            }}
            testId="member-search"
          />
        )}

        {showStaffFilter && (
          <DirectorySearch
            label="Staff name"
            roles={[
              Roles.COUNSELOR,
              Roles.ADMIN,
              Roles.SUPER_ADMIN,
              Roles.PEER,
            ]}
            value={form.values.staff}
            onOptionSelect={(newValue) => {
              form.setFieldValue('staff', newValue);
              form.setFieldTouched('staff');
            }}
            testId="staff-search"
          />
        )}

        {showProgramFilter && (
          <MultiSelect
            value={form.values.program}
            items={programItems}
            label="Program"
            fieldName="program"
            onChange={(selectedValues) => {
              form.setFieldValue('program', selectedValues);
              form.setFieldTouched('program');
            }}
          />
        )}

        <MultiSelect
          value={form.values.sessionType}
          items={SESSION_TYPES}
          label="Type of session"
          fieldName="sessionType"
          onChange={(selectedValues) => {
            form.setFieldValue('sessionType', selectedValues);
            form.setFieldTouched('sessionType');
          }}
        />

        <MultiSelect
          value={form.values.typeOfService}
          items={serviceTypeItems}
          label="Type of service"
          fieldName="typeOfService"
          onChange={(selectedValues) => {
            form.setFieldValue('typeOfService', selectedValues);
            form.setFieldTouched('typeOfService');
          }}
        />

        <MultiSelect
          value={form.values.status}
          items={statusItems}
          label="Document status"
          fieldName="status"
          onChange={(selectedValues) => {
            form.setFieldValue('status', selectedValues);
            form.setFieldTouched('status');
          }}
        />
      </Stack>

      <PfButton
        // $FlowFixMe
        isDisabled={!form.dirty}
        color="primary"
        onClick={form.handleSubmit}
        type="Submit">
        Apply
      </PfButton>
    </Box>
  );
};

const styles = {
  container: {
    minHeight: '480px',
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
  },
  spinner: {
    display: 'block',
    width: '25px',
    height: '25px',
  },
};
