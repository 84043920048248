// @flow
import React, {useEffect, useRef} from 'react';
import type {Node} from 'react';
import {Events, Hooks, Models} from '@wellstone-solutions/common';
import {Box, DataGrid, usePaging} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {useStoryColumnMap} from '../../hooks';
import {ListHeader} from './ListHeader';

const {usePaginatedData} = Hooks;
const {Story} = Models;
const EVENT_TABLE_NAME = 'StoryList';

export const StoryList = (): Node => {
  const initialLoad = useRef(true);
  const {eventStore, meStore} = useStores();

  const initialSortField = 'modified';
  const initialSortOrder = 'desc';
  const initialState = {
    sorting: {
      sortModel: [{field: initialSortField, sort: initialSortOrder}],
    },
  };

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
  });

  const queryResults = usePaginatedData({
    url: Story.routes.index(),
    params: {org_id: meStore.activeMembership.organization.id},
    dataTransformer: ({stories, total}) => ({
      stories: stories.map(Story.toUI),
      total,
    }),
    pageSize,
    currentPage: offset,
    sortField,
    sortOrder,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      queryResults.refetch({
        pageSize,
        currentPage: offset,
        sortField,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
  }, [currentPage, pageSize, sortField, sortOrder]);

  const columnMap = useStoryColumnMap();
  const columns = [
    columnMap.modified,
    columnMap.titleLink,
    columnMap.status,
    columnMap.host,
  ];

  const handleSortChange = (sortedColumns) => {
    let updatedSortField = '';
    let updatedSortOrder = '';

    if (sortedColumns?.length > 0) {
      const [sortedColumn] = sortedColumns;
      updatedSortField = sortedColumn.field;
      updatedSortOrder = sortedColumn.sort;

      eventStore.addEvent(Events.USER_SORTED_TABLE, {
        sort_field: updatedSortField,
        sort_order: updatedSortOrder,
        table: EVENT_TABLE_NAME,
      });
    }

    setSortField(updatedSortField);
    setSortOrder(updatedSortOrder);
  };

  const handlePageChange = (updatedPage) => {
    setCurrentPage(updatedPage);
  };

  const handlePageSizeChange = (updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  return (
    <Box>
      <Box mb={2}>
        <ListHeader />
      </Box>
      <DataGrid
        autoHeight
        loading={queryResults.isLoading}
        rows={queryResults.data?.stories ?? []}
        columns={columns}
        noRowsText="No stories"
        page={currentPage}
        pageSize={pageSize}
        paginationMode="server"
        sortingMode="server"
        initialState={initialState}
        rowCount={queryResults.data?.total ?? 0}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortModelChange={handleSortChange}
      />
    </Box>
  );
};
