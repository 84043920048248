// @flow
import React from 'react';
import type {Node} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery, useMutation} from '@tanstack/react-query';
import {groupsRead, groupsUpdate} from '@wellstone-solutions/api/v2/groups';
import type {Group as GroupType} from '@wellstone-solutions/api/v2/types';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {BridgeEventSources, Hooks, Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {ActionButtons, Form} from './Form';
import {groupSchema} from '../constants';
import PfLoader from 'components/shared/PfLoader';
import {ErrorPage} from 'components/ErrorPage';

const FORM_ID = 'new_group_form';

export const Edit = (): Node => {
  const {meStore} = useStores();
  const params = useParams();
  const groupId = params.group;

  useEventSource(BridgeEventSources.GROUPS_PAGE);

  const {data: response, error, isLoading} = useQuery({
    queryKey: ['group', groupId],
    queryFn: () => groupsRead(groupId),
    enabled: !!groupId,
  });

  const mutation = useMutation({
    mutationFn: ({groupId, values}) => groupsUpdate(groupId, values),
  });

  const group: GroupType = groupSchema.cast({
    name: response?.data.name,
    description: response?.data.description,
    isPublic: response?.data.isPublic,
    organization: meStore.me.membership.organization.id,
    programs: response?.data.programs,
    category: response?.data.category,
  });

  const form = Hooks.useForm({
    enableReinitialize: true,
    initialValues: group,
    schema: groupSchema,
    validationEngine: 'yup',
    onSubmit: async (values, {resetForm}) => {
      try {
        await mutation.mutateAsync({groupId, values});

        showAlert(`Group updated successfully!`, ALERT_TYPES.SUCCESS);
      } catch (error) {
        Object.entries(error.response?.data).forEach(([key, values]) => {
          form.setFieldError(key, values.join(' '));
        });

        showAlert(`Unable to update new group.`, ALERT_TYPES.ERROR);
      }
    },
  });

  if (error) {
    return <ErrorPage message="Group not found" isFullScreen={false} />;
  }

  if (isLoading) {
    return <PfLoader />;
  }

  return (
    <Box>
      <Stack direction={{xs: 'column', md: 'row'}}>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4}}>
          <Typography
            variant="h4"
            sx={{color: Theme.colorPalette.darkest, mb: 1, fontWeight: 'bold'}}>
            Edit Group Details
          </Typography>
          <Typography sx={{color: Theme.colorPalette.darkest, mb: 2}}>
            All fields are required unless indicated as optional.
          </Typography>

          <Form form={form} formId={FORM_ID} />
          <ActionButtons formId={FORM_ID} form={form} />
        </Stack>
      </Stack>
    </Box>
  );
};
