// @flow
import React, {useCallback, useRef, useState} from 'react';
import type {Node} from 'react';

import {Theme, Validation} from '@wellstone-solutions/common';
import type {FormType, UIStoryType} from '@wellstone-solutions/common';
import {
  Box,
  ErrorMessage,
  Form as CommonForm,
  FormRow,
  MarkdownViewer,
  RichTextEditor,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from '@wellstone-solutions/web';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {TEMPORARY_IMAGE_FOLDER} from 'constants/Images';
import {useToastUIImageUploadHandler} from 'hooks/useToastUIImageUploadHandler';
import {STATUSES} from '../../../constants';
import {StoryContentWrapper} from '../../StoryContentWrapper';
import {TagSelector} from './TagSelector';
import {FileDragDrop} from './FileDragDrop';

type PropsType = {
  formId: string,
  form: FormType<UIStoryType>,
  storyStatus?: 'pending' | 'approved' | 'deleted' | null,
};

const LinkMimeTypes = {
  YOUTUBE: 'youtube',
};

export const Form = ({form, formId, storyStatus}: PropsType): Node => {
  const [isTagTyping, setIsTagTyping] = useState<boolean>(false);
  const markdownRef = useRef('');

  const getImageMimeType = (mimeType) =>
    form.values.mediaUrl && Validation.isImageMimeTypeOptional(mimeType)
      ? LinkMimeTypes.YOUTUBE
      : mimeType;

  const getLinkMimeType = (mimeType) =>
    Validation.isMimeTypeOptional(form.values.mediaMimeType)
      ? mimeType
      : form.values.mediaMimeType;

  const handleContentChange = useCallback(() => {
    // $FlowFixMe
    const content = markdownRef.current.getInstance().getMarkdown();
    form.setFieldTouched('desc');
    form.setFieldValue('desc', content);
  }, []);

  const handleImageUpload = useToastUIImageUploadHandler({
    folder: TEMPORARY_IMAGE_FOLDER,
    onError: () => {
      showAlert('Something went wrong.  Please try again.', ALERT_TYPES.ERROR);
    },
  });

  const onFileSelect = (file: any) => {
    // $FlowIgnoreMe
    form.setTouched({
      mediaImage: true,
      mediaMimeType: true,
    });
    // $FlowIgnoreMe
    form.setValues({
      ...form.values,
      mediaImage: file,
      mediaMimeType: getImageMimeType(file.type),
    });
  };

  const onMediaUrlChange = ({target: {value}}) => {
    // $FlowIgnoreMe
    form.setTouched({
      mediaUrl: true,
      mediaMimeType: true,
    });
    // $FlowIgnoreMe
    form.setValues({
      ...form.values,
      mediaUrl: value,
      mediaMimeType: getLinkMimeType(LinkMimeTypes.YOUTUBE),
    });
  };

  const updateTags = (tags: Array<String>) => {
    // $FlowIgnoreMe
    form.setValues({
      ...form.values,
      tags,
    });
  };

  const isReadOnly = storyStatus === STATUSES.published;
  const relativeImagePath = (form.values.mediaImageUrl || '').split('/').pop();

  //TODO: Make images 100% width in toast ui
  return (
    <CommonForm
      data-testid="story-form"
      id={formId}
      // $FlowFixMe
      onSubmit={form.handleSubmit}>
      <Stack direction="column">
        <FormRow>
          <TextField
            formField="title"
            label="Title"
            inputProps={{maxLength: 128}}
            onChange={form.handleFieldChange('title')}
            value={form.values.title}
            disabled={isReadOnly}
            required
          />
        </FormRow>
        <FormRow>
          <TextField
            formField="host"
            label="Author"
            inputProps={{maxLength: 128}}
            onChange={form.handleFieldChange('host')}
            value={form.values.host}
            disabled={isReadOnly}
            onBlur={() => {
              if (form.values.host === '') {
                form.setFieldTouched('host');
                form.setFieldValue('host', 'Anonymous');
              }
            }}
            required
          />
        </FormRow>
        <Typography sx={{pl: 0.5, pt: 0.5, fontSize: 14}}>
          Enter the author or leave blank for anonymous.
        </Typography>
        <FormRow>
          <Typography
            variant="body2"
            sx={styles.sectionTitle}
            className={isReadOnly ? 'disabled' : ''}>
            Cover Image *
          </Typography>
          <Stack direction="row" alignItems="center">
            <FileDragDrop
              onFileSelect={onFileSelect}
              fileName={form.values.mediaImage?.name || relativeImagePath}
              imageOnly={true}
              disabled={isReadOnly}
            />
          </Stack>
          <ErrorMessage
            name="mediaImage"
            errors={form.errors}
            touched={form.touched}
          />
          <ErrorMessage
            name="mediaMimeType"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <TextField
            formField="mediaUrl"
            label="Media url"
            inputProps={{maxLength: 128}}
            onChange={onMediaUrlChange}
            value={form.values.mediaUrl || ''}
            disabled={isReadOnly}
          />
          <ErrorMessage
            name="mediaUrl"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <TextField
            fullWidth
            multiline
            minRows={2}
            inputProps={{maxLength: 128}}
            label="Preview Copy"
            formField={'abstract'}
            value={form.values.abstract}
            onChange={form.handleFieldChange('abstract')}
            disabled={isReadOnly}
            required
          />
        </FormRow>
        <FormRow>
          <Typography
            variant="h6"
            sx={styles.sectionTitle}
            className={isReadOnly ? 'disabled' : ''}>
            Story Content *
          </Typography>
          {isReadOnly ? (
            <Box sx={styles.markdownViewerBox}>
              <MarkdownViewer value={form.values.desc} />
            </Box>
          ) : (
            <StoryContentWrapper>
              <RichTextEditor
                initialValue={form.values.desc}
                customImageHandler={handleImageUpload}
                toolbarItems={[
                  [
                    'heading',
                    'bold',
                    'italic',
                    'hr',
                    'ul',
                    'ol',
                    'quote',
                    'link',
                    'image',
                  ],
                ]}
                onChange={handleContentChange}
                height="260px"
                /* $FlowFixMe */
                ref={markdownRef}
              />
              <ErrorMessage
                name="desc"
                errors={form.errors}
                touched={form.touched}
              />
            </StoryContentWrapper>
          )}
        </FormRow>
        <FormRow>
          <Typography
            variant="h6"
            sx={styles.sectionTitle}
            className={isReadOnly ? 'disabled' : ''}>
            Keywords
          </Typography>
          <Typography variant="body2">
            A few keywords not mentioned in the title
          </Typography>
          <TagSelector
            tags={form.values.tags}
            updateValues={updateTags}
            disabled={isReadOnly}
            setIsTyping={setIsTagTyping}
          />
          <Tooltip
            open={isTagTyping}
            placement="top"
            title="Hit the ⏎ return key to add your keyword.">
            <span style={styles.tooltipAnchor} />
          </Tooltip>
        </FormRow>
      </Stack>
    </CommonForm>
  );
};

const styles = {
  posterImage: {
    marginLeft: '16px',
    borderRadius: '2px',
    height: '130px',
  },
  posterButton: {
    height: '40px',
  },
  sectionTitle: {
    color: 'black',
    marginBottom: '4px',
    '&.disabled': {
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
    },
  },
  errorMessage: {
    color: Theme.colorPalette.negative,
  },
  markdownViewerBox: {
    border: `1px solid ${Theme.colorPalette.medium}`,
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
    px: Theme.spacing.micro,
    py: Theme.spacing.micro,
    borderRadius: 1,
    maxHeight: '240px',
    overflowY: 'scroll',
  },
  tooltipAnchor: {
    width: '100%',
    marginTop: '40px',
    height: 0,
  },
};
