// @flow
import React from 'react';
import type {Node} from 'react';
import {useMutation} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {groupsCreate} from '@wellstone-solutions/api/v2/groups';
import type {Group as GroupType} from '@wellstone-solutions/api/v2/types';
import {Box, Stack, Typography} from '@wellstone-solutions/web';
import {BridgeEventSources, Hooks, Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {ActionButtons, Form} from './Form';
import {groupSchema} from '../constants';

const FORM_ID = 'new_group_form';

export const New = (): Node => {
  const {groupStore, programStore, meStore} = useStores();

  useEventSource(BridgeEventSources.GROUPS_PAGE);

  const mutation = useMutation({
    mutationFn: groupsCreate,
    onSuccess: () => {},
  });

  const navigate = useNavigate();

  // Not using model Factory due to new apiv2 shape
  const group: GroupType = {
    name: '',
    description: '',
    isPublic: true,
    organization: meStore.me.membership.organization.id,
    programs: [],
    category: '',
  };

  const form = Hooks.useForm({
    initialValues: group,
    schema: groupSchema,
    validationEngine: 'yup',
    onSubmit: async (values, {resetForm}) => {
      try {
        const response = await mutation.mutateAsync(values);

        resetForm();

        // TODO: Replace with ReactQuery way once list is using RQ
        await programStore.getAllPrograms();

        Promise.all([
          groupStore.loadGroups(meStore.me.membership.organization.id, false),
          groupStore.requestMyGroups(meStore.permittedGroupIds),
        ]);
        // End replace

        navigate(`/groups/${response.data.id}`);
      } catch (error) {
        Object.entries(error.response?.data).forEach(([key, values]) => {
          form.setFieldError(key, values.join(' '));
        });

        showAlert(`Unable to save new group.`, ALERT_TYPES.ERROR);
      }
    },
  });

  return (
    <Box>
      <Stack direction={{xs: 'column', md: 'row'}}>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4}}>
          <Typography
            variant="h4"
            sx={{color: Theme.colorPalette.darkest, mb: 1, fontWeight: 'bold'}}>
            Create Group Details
          </Typography>
          <Typography sx={{color: Theme.colorPalette.darkest, mb: 2}}>
            All fields are required unless indicated as optional.
          </Typography>

          <Form form={form} formId={FORM_ID} />
          <ActionButtons formId={FORM_ID} form={form} />
        </Stack>
      </Stack>
    </Box>
  );
};
