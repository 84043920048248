// @flow
import React, {useEffect, useMemo, useRef} from 'react';
import type {Node} from 'react';
import {Events, Hooks, Models} from '@wellstone-solutions/common';
import {DataGrid, usePaging} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {useMemberColumnMap} from 'modules/member';
import {AccessControl} from 'modules/rbac';

const {usePaginatedData} = Hooks;
const {Member} = Models;

type PropsType = {
  groupId: string,
  filter: string,
  dataGridRef?: any,
};

const DISCHARGED = 'discharged';
const EVENT_TABLE_NAME = 'GroupMemberList';

const getParams = (groupId, filter) => ({
  group_id: groupId,
  role: 'patient',
  ...(filter === DISCHARGED ? {is_discharged: 1} : {is_active: 1}),
});

export const GroupMemberList = ({
  groupId,
  filter,
  dataGridRef,
}: PropsType): Node => {
  const initialLoad = useRef(true);
  const {eventStore, meStore, RBACStore} = useStores();

  const params = useMemo(() => getParams(groupId, filter), [groupId, filter]);

  const initialSortField = 'name';
  const initialSortOrder = 'asc';

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
    isPersisted: false,
    initialPageSize: 10,
  });

  const queryResults = usePaginatedData({
    url: Member.routes.index(meStore.organizationId),
    dataTransformer: ({members, total}) => ({
      members: members.map(Member.toUI),
      total,
    }),
    params,
    pageSize,
    currentPage: offset,
    sortField,
    sortOrder,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      queryResults.refetch({
        pageSize,
        currentPage: offset,
        params,
        sortField,
        sortOrder,
      });
    }
  };

  if (dataGridRef) {
    dataGridRef.current = {refetch: refetchData};
  }

  useEffect(() => {
    setSortField(initialSortField);
    setSortOrder(initialSortOrder);
    setCurrentPage(0);
  }, [params]);

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
  }, [params, currentPage, pageSize, sortField, sortOrder]);

  const columnMap = useMemberColumnMap({
    refetch: refetchData,
    groupId: groupId,
  });

  const columns = [
    columnMap.nameLink,
    columnMap.lastCheckIn,
    columnMap.hasCommunityAccess,
    columnMap.identifiesAs,
    {
      ...columnMap.groupStartDate,
      headerName:
        filter === DISCHARGED ? 'Last Group Start Date' : 'Group Start Date',
    },
    filter === DISCHARGED
      ? columnMap.groupDischargeDate
      : columnMap.groupEndDate,
    columnMap.lastOpened,
  ];

  // Only admins/super admins can do actions right now
  if (RBACStore.hasAccess(AccessControl.members.viewListActions)) {
    columns.push(columnMap.actions);
  }

  const handleSortChange = (sortedColumns) => {
    let updatedSortField = '';
    let updatedSortOrder = '';

    if (sortedColumns?.length > 0) {
      const [sortedColumn] = sortedColumns;
      updatedSortField = sortedColumn.field;
      updatedSortOrder = sortedColumn.sort;

      eventStore.addEvent(Events.USER_SORTED_TABLE, {
        sort_field: updatedSortField,
        sort_order: updatedSortOrder,
        table: EVENT_TABLE_NAME,
      });
    }

    setSortField(updatedSortField);
    setSortOrder(updatedSortOrder);
  };

  const handlePageChange = (updatedPage) => {
    setCurrentPage(updatedPage);
  };

  const handlePageSizeChange = (updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  const initialState = {
    sorting: {
      sortModel: [{field: initialSortField, sort: initialSortOrder}],
    },
  };

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => 'auto'}
      loading={queryResults.isLoading}
      rows={queryResults.data?.members ?? []}
      columns={columns}
      noRowsText="No members"
      page={currentPage}
      pageSize={pageSize}
      rowsPerPageOptions={[5, 10, 25]}
      paginationMode="server"
      sortingMode="server"
      initialState={initialState}
      rowCount={queryResults.data?.total ?? 0}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      onSortModelChange={handleSortChange}
    />
  );
};
