// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {BridgeEventSources, Events} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {GroupList} from './GroupList';

export const GroupRoot = (): Node => {
  const {appUIStore, eventStore} = useStores();

  useEventSource(BridgeEventSources.GROUPS_PAGE);

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: 'Groups',
        url: '/groups',
        active: true,
      },
    ]);

    eventStore.addEvent(Events.VIEWED_GROUP_LIST);
  }, [appUIStore]);

  return <GroupList />;
};
