// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';

import {Typography} from '@wellstone-solutions/web';
import {Events, Models, Theme} from '@wellstone-solutions/common';
import type {
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';

import {NAVIGATION_NAMES} from 'constants/Navigation';
import {useStores} from 'hooks/useStores';
import {Edit} from './Edit';
import {View} from './View';

type PropsType = {
  data: {
    calendarEvent: UICalendarEventType,
    calendarEventInvitations: Array<UICalendarEventInvitationType>,
  },
};

const {CalendarEvent} = Models;

export const Detail = ({
  data: {calendarEvent, calendarEventInvitations},
}: PropsType): Node => {
  const {appUIStore, eventStore, meStore} = useStores();

  const eventName = calendarEvent ? calendarEvent.title : 'Invalid Event';

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: NAVIGATION_NAMES.calendar,
        url: '/calendar',
      },
      {
        name: eventName,
        url: `/calendar/calendar-event/${String(calendarEvent?.id)}`,
        active: true,
      },
    ]);

    if (calendarEvent) {
      eventStore.addEvent(Events.VIEWED_CALENDAR_EVENT_DETAIL, {
        calendar_event_id: calendarEvent.id,
        can_edit: canEdit,
      });
    }
  }, [eventName, appUIStore, calendarEvent]);

  if (!calendarEvent) {
    return (
      <Typography
        variant="h4"
        sx={{fontWeight: 300, color: Theme.colorPalette.darker}}>
        Calendar event does not exist, or you may not have access
      </Typography>
    );
  }

  const isOwner = calendarEvent?.owner === meStore.me.id;
  const isEventCanceled = CalendarEvent.isCanceled(calendarEvent);
  const canEdit = !isEventCanceled && isOwner;

  return (
    <>
      {canEdit ? (
        <Edit
          calendarEvent={calendarEvent}
          invitations={calendarEventInvitations}
        />
      ) : (
        <View
          calendarEvent={calendarEvent}
          invitations={calendarEventInvitations}
        />
      )}
    </>
  );
};
