import React, {useState} from 'react';
import PfModal from './PfModal';
import {EventEmitter} from '../../utils/EventEmitter';

const MultiStepModal = (props) => {
  const [step, setStep] = useState(0);

  const closeModal = () => {
    EventEmitter.dispatch('closeModal');
    if (props.onCloseModal) {
      props.onCloseModal();
    }
    setStep(0);
  };

  const nextStep = () => {
    if (step === props.steps.length - 1) {
      closeModal();
    } else {
      setStep(step + 1);
    }
  };

  const {steps, button, ...childProps} = props;

  return (
    <PfModal
      button={{text: 'Open Modal', ...button}}
      large
      modal={{
        closeOnBlur: true,
        showClose: false,
        closeOnEsc: true,
      }}
      onCloseModal={props.onCloseModal}>
      {steps?.length &&
        React.cloneElement(steps[step], {
          ...childProps,
          nextStep: nextStep,
          closeModal: closeModal,
        })}
    </PfModal>
  );
};

export default MultiStepModal;
