// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';

import {
  Button,
  IconNames,
  Icon,
  List,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

import {useStores} from 'hooks/useStores';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {SiteHeaderDrawer} from './SiteHeaderDrawer';
import {ActiveCount} from 'components/ActiveCount/ActiveCount';

import {
  SessionDocumentNotification,
  CalendarEventNotification,
} from 'modules/notifications/components/Notifications';
import type {NotificationType} from 'modules/notifications/types';

type PropsType = {};
export const NotificationsPanel: ComponentType<PropsType> = observer(
  (PropsType): Node => {
    const {appNotificationStore} = useStores();
    const theme = useTheme();
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
      setNotificationAnchorEl(null);
    };
    const handleClick = (event) => {
      event.stopPropagation();
      let anchor =
        event.target.closest('.header_tool') ?? event.target.parentNode;
      setNotificationAnchorEl(anchor);
    };
    const getNotificationTemplate = (
      notification: NotificationType<any>,
      key: number,
    ): React.Element | void => {
      if (notification.type === 'session_documentation') {
        return (
          <SessionDocumentNotification
            item={notification}
            closeMenu={handleClose}
            key={key}
          />
        );
      }
      if (notification.type === 'calendar_event') {
        return (
          <CalendarEventNotification
            item={notification}
            closeMenu={handleClose}
            key={key}
          />
        );
      }
    };

    return (
      <>
        <Button
          onClick={handleClick}
          sx={style.button}
          tabIndex={0}
          data-testid="header-notifications-button">
          <Icon
            name={IconNames.Bell}
            size={isMobile ? 22 : 24}
            color={Theme.colorPalette.onPrimary}
          />
          <ActiveCount
            count={appNotificationStore.count}
            sx={isMobile ? style.badgeMobile : style.badge}
          />
        </Button>
        <SiteHeaderDrawer
          onClose={handleClose}
          anchorEl={notificationAnchorEl}
          title="Notifications"
          data-testid="header-notifications-panel"
          transitionDirection="left"
          closeButton={false}
          transitionTimeout={0}>
          <List sx={style.list}>
            {appNotificationStore.notifications.length > 0 ? (
              appNotificationStore.notifications.map(getNotificationTemplate)
            ) : (
              <Stack sx={style.emptyList} data-testid="empty-notifications">
                <Icon
                  name={IconNames.PeaceHand}
                  size={24}
                  color={Theme.colorPalette.darker}
                  sx={style.emptyIcon}
                />
                <Typography variant="body1" sx={{fontWeight: 400}}>
                  You're all caught up!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{color: Theme.colorPalette.dark}}>
                  Way to go.
                </Typography>
              </Stack>
            )}
          </List>
        </SiteHeaderDrawer>
      </>
    );
  },
);

const style = {
  button: {
    padding: '8px 0',
    minWidth: '40px',
  },
  badge: {
    background: Theme.colorPalette.red,
    position: 'absolute',
    right: '-8px',
    top: '-3px',
    width: '20px',
    height: '20px',
    textOverflow: 'clip',
    ' .MuiChip-label': {
      textOverflow: 'clip',
      padding: 0,
      fontWeight: 200,
    },
  },
  badgeMobile: {
    background: Theme.colorPalette.red,
    position: 'absolute',
    right: '2px',
    top: '0px',
    width: '20px',
    height: '20px',
    textOverflow: 'clip',
    ' .MuiChip-label': {
      textOverflow: 'clip',
      padding: 0,
      fontWeight: 200,
    },
  },
  emptyList: {
    fontFamily: 'DM Sans, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: 2,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyIcon: {
    display: 'flex',
  },
  list: {
    padding: 0,
    maxHeight: '310px',
    overflowY: 'auto',
  },
};
