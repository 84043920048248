// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Modal} from '@mui/material';
import {
  Box,
  Stack,
  Select,
  Typography,
  Icon,
  IconNames,
  Fab,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {CreateWizard} from '../CreateWizard';
import {useStores} from 'hooks/useStores';
import {hasFlag, DISABLE_ADD_NEW_MEMBER} from 'constants/FeatureFlags';
import {ALL_MEMBERS, MEMBER_LIST_DESCRIPTION} from '../../constants';
import {AccessControl} from 'modules/rbac';

type PropsType = {
  filter: string,
  setFilter: (string) => void,
};

export const ListHeader: ComponentType<PropsType> = observer(
  ({filter, setFilter}): Node => {
    const [showModal, setShowModal] = useState(false);
    const [currentProgram, setCurrentProgram] = useState(filter);
    const {meStore, RBACStore} = useStores();
    const theme = useTheme();
    const mediumBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
    const allMembersItem = {
      label: 'All Members',
      value: ALL_MEMBERS,
    };

    const programFilterItems = [
      ...meStore.myPrograms.map(({id, name}) => ({
        label: name,
        value: id,
      })),
      allMembersItem,
    ];

    const toggleModal = (event, reason) => {
      if (reason !== 'backdropClick') {
        setShowModal(!showModal);
      }
    };

    const onCurrentProgramChange = (newProgram) => {
      setCurrentProgram(newProgram);
      setFilter(newProgram);
    };

    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{mb: 4}}>
          <Box>
            <Typography variant="h1" sx={styles.title}>
              Members
            </Typography>
            <Typography
              variant="body"
              sx={{display: {xs: 'none', md: 'block'}}}>
              {MEMBER_LIST_DESCRIPTION}
            </Typography>
          </Box>
          <Box>
            {RBACStore.hasAccess(AccessControl.members.addMember) &&
              !hasFlag(DISABLE_ADD_NEW_MEMBER, meStore.features) && (
                <Box>
                  {mediumBreakPoint ? (
                    <Fab
                      size="medium"
                      aria-label="add"
                      color="primary"
                      onClick={toggleModal}>
                      <Icon
                        name={IconNames.Plus}
                        color={Theme.colorPalette.onPrimary}
                      />
                    </Fab>
                  ) : (
                    <Fab
                      variant="extended"
                      color="primary"
                      onClick={toggleModal}
                      sx={{whiteSpace: 'nowrap'}}>
                      + New Member
                    </Fab>
                  )}
                  <Modal open={showModal} onClose={toggleModal}>
                    <div>
                      <CreateWizard onCancel={toggleModal} />
                    </div>
                  </Modal>
                </Box>
              )}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Select
            canUnselect={false}
            value={currentProgram}
            items={programFilterItems}
            label="Program"
            data-testid="program-select"
            containerProps={{
              sx: {
                minWidth: {
                  xs: '100%',
                  md: 300,
                },
              },
            }}
            sx={{
              backgroundColor: Theme.colorPalette.lightest,
            }}
            onChange={(ev) => {
              onCurrentProgramChange(ev.target.value);
            }}
          />
        </Stack>
      </>
    );
  },
);

const styles = {
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '700',
    color: Theme.colorPalette.darkest,
  },
};
