// @flow
import React, {Fragment} from 'react';
import type {ComponentType, SyntheticEvent} from 'react';
import {observer} from 'mobx-react';
import {useLocation} from 'react-router-dom';
import {List, Divider} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {getAuthorizedRoutes} from 'pages/AuthorizedRoutes';
import {PRIMARY_NAVIGATION_ORDER} from 'constants/Navigation';

import {NavigationElement} from './NavigationElement';

type PropsType = {
  onNavigate: (event: SyntheticEvent<HTMLButtonElement>) => void,
  open: boolean,
};
export const PrimaryNavigation: ComponentType<PropsType> = observer(
  ({onNavigate, open = true}) => {
    const stores = useStores();
    const {meStore} = stores;
    const location = useLocation();

    const routes = getAuthorizedRoutes(meStore).filter((r) => {
      const isAllowed = r.allowed && meStore.isAllowedRole(r.allowed);
      return r.showInMenu && isAllowed;
    });

    const navigation_groups = PRIMARY_NAVIGATION_ORDER.map((group) => {
      return group
        .map((path) => {
          return routes.find((r) => r.path === path) || null;
        })
        .filter((n) => n);
    });

    const handleNavigateClick = () => {
      onNavigate();
    };

    return (
      <List
        data-testid="primary-navigation-root"
        sx={{
          padding: '0 0 0 5px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
          scrollbarGutter: 'stable',
        }}>
        {navigation_groups.map((group, i) => (
          <Fragment key={`nav-group-${i}`}>
            {i !== 0 && group.length !== 0 && (
              <Divider
                sx={open ? {margin: '8px 24px'} : {margin: '8px 6px'}}
                component="hr"
                key={`divider-${i}`}
                variant="middle"
                flexItem
              />
            )}
            {group.map((route) => (
              <NavigationElement
                route={route}
                key={route.path}
                open={open}
                selected={location.pathname.indexOf(route.path) === 1}
                onNavigate={handleNavigateClick}
              />
            ))}
          </Fragment>
        ))}
      </List>
    );
  },
);
