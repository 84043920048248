// @form
import React from 'react';
import {Theme} from '@wellstone-solutions/common';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Button,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';

type PropsType = {
  formId: string,
  form: any,
};
export const ActionButtons = ({formId, form}: PropsType): Node => {
  const navigate = useNavigate();
  const saveDisabled = !form.isValid || !form.dirty || form.isSubmitting;

  return (
    <Stack direction="row" sx={{mt: 5}}>
      <Button
        variant="outlined"
        onClick={() => navigate('/groups')}
        sx={styles.cancelButton}>
        Cancel
      </Button>
      <Button
        form={formId}
        type="submit"
        disabled={saveDisabled}
        variant="contained"
        data-testid={'group-add-new'}
        sx={styles.saveButton}>
        <Icon
          name={IconNames.FloppyDisk}
          color={
            saveDisabled
              ? Theme.colorPalette.mediumDark
              : Theme.colorPalette.lightest
          }
          size={20}
        />
        <Typography sx={{px: 1, py: 0.5}}>Save</Typography>
      </Button>
    </Stack>
  );
};

const styles = {
  cancelButton: {
    width: 125,
  },
  saveButton: {
    width: 125,
    ml: 3,
  },
};
